<template>
  <div class="main">
    <van-nav-bar
        title="检查员管理"
        left-text="返回"
        right-text=""
        left-arrow
        @click-left="onClickLeft"
     />
    <div class="main">
      <div class="jian"></div>
      <div style="padding: 15px">
        <van-row class="item"  type="flex" justify="left" align="center" v-for="(x,index) in data" :key="index">
          <van-col span="18"><div class="left" style="font-size: 1.2rem;font-weight: bolder">{{x.trueName}}</div>
            <div style="padding-top: 10px;color: #999999">
              <van-row  type="flex" justify="left" align="center">
                <van-col span="3" ><div class="left">{{x.sex}}</div></van-col>
                <van-col span="3" ><div class="left">{{x.phone}}</div></van-col>
              </van-row>
            </div>

          </van-col>
          <van-col span="3" ><div class="tubiao" @click="memberEdit(x)"><van-icon name="edit" /></div></van-col>
          <van-col span="3" ><div class="tubiao" @click="memberDel(x.id)"><van-icon name="delete-o" /></div></van-col>
        </van-row>

      </div>
      <van-button type="primary" icon="plus" size="large" @click="openAdd" style="position:fixed;width: 100%;left: 0;bottom:0px;">添加人员</van-button>
<!-- <div style="position:fixed;width: 100vw;bottom:0px;"><van-button plain hairline type="info" style="width: 50vw" @click="openAdd">添加人员</van-button></div> -->
    </div>


  </div>

</template>

<script>
import { getMember,getMemberDel } from '@/api/get'
import Vue from 'vue';
import {NavBar, Col, Row, Icon, Cell, Popup, Button, Toast} from 'vant';
Vue.use(Col);
Vue.use(Row);
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(Popup);
Vue.use(Button);
export default {
  name: "inspector",
  data(){
    return{
      userType:40,
      data:[],
      showPickerDate: false,
      currentDate: new Date(),
    }},
  mounted() {
    if(localStorage.userType!=50){
      this.$router.push({ path: '/home', query: {}})
      return
    }
    this.loadData()
     window.aplus_queue.push({
          action: 'aplus.setMetaInfo',
          arguments: ['_hold', 'BLOCK']
    })
     // 单页应用 或 “单个页面”需异步补充PV日志参数还需进行如下埋点：
    window.aplus_queue.push({
      action: 'aplus.setMetaInfo',
      arguments: ['aplus-waiting', 'MAN']
    });//
    // 单页应用路由切换后 或 在异步获取到pv日志所需的参数后再执行sendPV：
    window.aplus_queue.push({
      'action':'aplus.sendPV',
      'arguments':[{
        is_auto: false
      }, {
        // 当前你的应用信息，此两行请勿修改
        sapp_id: '21436',
        sapp_name: 'yaojian',
        // 自定义PV参数key-value键值对（只能是这种平铺的json，不能做多层嵌套），如：
        x: 111,
        y: 222,
        page_id: 'inspector',
        page_name: '检查员管理',
        page_url: '/inspector'
      }]
    })
    window.aplus_queue.push({
						action: 'aplus.setMetaInfo',
						arguments: ['_hold', 'START']
			})
  },
  methods:{
    memberDel(id){
      getMemberDel(id).then(res => {
        if (res.data.code === 200){
          Toast("已删除")
          this.loadData()
        }
      })
    },
    memberEdit(row){
      this.$router.push({ path: '/add', query: row})
    },
    loadData(){
      let regulatoryId=localStorage.storeId
      getMember({regulatoryId:regulatoryId}).then(res => {
        if (res.data.code === 200){
          this.data=res.data.data
        }
      })
    },
    openAdd(){
      this.$router.push({ path: '/add', query: {}})
    },

    onClickLeft() {
      this.$router.push({ path: '/mine', query: {id:1}})
    },
  }
}
</script>

<style scoped lang="css" src="./assets/index.css" />